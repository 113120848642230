@tailwind base;
@tailwind components;
@tailwind utilities;

/*Dropdown*/
.group:hover .group-hover\:scale-100 { transform: scale(1) }
.group:hover .group-hover\:-rotate-180 { transform: rotate(180deg) }
.scale-0 { transform: scale(0) }

.googleMaps iframe{ width: 100%; height: 100%; max-height: 500px;}

.swiper-pagination-bullet{
    margin: 0 !important;
}

.swiper-pagination-bullet-active{
    background-color: #204040;
    color:#fff;
    position: relative;
    font-weight: bold;
    background-image: url("/images/icons/boy_girl_breadcrumb.svg");
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-position-x: 90%;
}

.swiper-pagination-bullet-active span::before{
    content: url("/images/icons/carousel_arrow.svg");
    position: absolute;
    left: 10px;
}

.swiper-pagination-bullet:only-child{
    display: block;
}

@layer base {
    main{
        @apply font-opensans overflow-hidden
    }

    h1{
        @apply text-4xl text-subColor font-bold after:content-[""] after:w-[60px] after:h-[3px] after:block after:bg-mainColor after:my-3;
    }

    h2{
        @apply text-2xl text-subColor font-bold after:content-[""] after:w-[60px] after:h-[3px] after:block after:bg-mainColor after:my-3;
    }

    h3{
        @apply text-2xl text-mainColor font-bold after:content-[""] after:w-[60px] after:h-[3px] after:block after:bg-subColor after:my-3;
    }

    p{
        @apply mb-4
    }
}

@layer components {
    .btn-mainColor{
        @apply bg-mainColor text-white font-opensans text-base sm:text-lg rounded-full border-b-4 border-subColor px-6 py-2 transition-all inline-block my-4 hover:bg-subColor hover:border-mainColor border-l border-r border-t after:content-[url('/images/icons/arrow_btn.svg')] after:ml-2.5;
    }

    .btn-subColor{
        @apply bg-subColor text-white font-opensans text-base sm:text-lg rounded-full border-b-4 border-mainColor px-6 py-2 transition-all inline-block my-4 hover:bg-mainColor hover:border-subColor border-l border-r border-t after:content-[url('/images/icons/arrow_btn.svg')] after:ml-2.5;
    }
}
